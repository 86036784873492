<template>
  <div>
    <NavBar :title="title" :left-arrow="hasBack" @click-left="$router.go(-1)" />
  </div>
</template>

<script>
import { NavBar } from "vant";
export default {
  components: { NavBar },
  props: {
    title: {
      type: String | Number,
      default: "",
      require: true,
    },
    hasBack: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="less" scoped>
</style>